import IconButton from "@rjsf/core/lib/components/IconButton";
import AddButton from "@rjsf/core/lib/components/AddButton";
import React, { useState, useMemo } from "react";
import { Button } from "reactstrap";

import {
    canExpand,
    ADDITIONAL_PROPERTY_FLAG,
} from "@rjsf/core/lib/utils";
import { interpolate } from "../../util/mapObject";
import Jnx, { parseJnxExpr } from "../../util/jnx";
import { useSideChannelSubscription } from "../../util/useSideChannel";
import getPathFromId from "../../util/getPathFromId";

const btnStyle = { flex: '0 0 32px', paddingLeft: 6, paddingRight: 6, fontWeight: "bold" };


function DefaultObjectFieldTemplate(props) {
    const {
        registry,
        description,
        idSchema: { $id },
        uiSchema,
        disabled,
        formContext,
        formData,
        onAddClick,
        properties,
        readonly,
        schema,
        title,
        DescriptionField
    } = props;

    const {
        'ui:title': uiTitle,
        'ui:asPropertiesTable': asPropertiesTable,
        'ui:sectionType': uiSectionType,
        'ui:expandable': expandable,
        'ui:expandedDefault': expandedDefault = true,
    } = uiSchema;

    const canBePropertiesTable = useMemo(() => (
        asPropertiesTable &&
        (!schema.additionalProperties || schema.additionalProperties.type !== "object") &&
        (Object.values(schema.properties || {}).every(({ type }) => type !== "object"))
    ), [schema, asPropertiesTable]);

    const { sideChannel } = formContext;
    const [rootFormData, formObject] = useSideChannelSubscription(sideChannel, [0, 1]) || [{}];

    const bindings = useMemo(() => ({
        formContext,
        formObject,
        rootFormData
    }), [
        formContext,
        formObject,
        rootFormData
    ]);

    const [expanded, setExpanded] = useState(expandedDefault);
    function toggleExpand(e) {
        e.preventDefault();
        e.stopPropagation();
        setExpanded(!expanded);
    }

    return (<fieldset id={$id}>
        {(uiTitle || title) && (<TitleField
            id={$id}
            isSection
            sectionType={uiSectionType}
            title={uiTitle || title}
            bindings={bindings}
            expandable={expandable}
            expanded={expanded}
            toggleExpand={toggleExpand}
        />)}
        {expanded || !expandable ? (<>
            {description && (
                <DescriptionField
                    id={`${$id}__description`}
                    description={description}
                    formContext={formContext}
                />
            )}
            {canBePropertiesTable ? (<PropertiesTable
                properties={properties}
                uiSchema={uiSchema}
                onAddClick={canExpand(schema, uiSchema, formData) ? onAddClick(schema) : null}
                readonly={disabled || readonly}
                registry={registry}
            />) : (<>
                {properties.map(prop => prop.content)}
                {canExpand(schema, uiSchema, formData) && (
                    <AddButton
                        className="object-property-expand"
                        onClick={onAddClick(schema)}
                        disabled={disabled || readonly}
                    />
                )}
            </>)}
        </>) : null}
    </fieldset>);
}

function PropertiesTable({ properties, uiSchema, onAddClick, readonly, registry }) {
    const {
        "ui:keyName": keyName = "",
        "ui:valueName": valueName = "",
    } = uiSchema || {}
    const hasHeader = keyName || valueName;
    const { SchemaField } = registry.fields;
    return (<table>
        {hasHeader && properties.length ? (<thead><tr><th>{keyName}</th><th>{valueName}</th></tr></thead>) : null}
        <tbody>
            {properties.map(({ content }, idx) => {
                const props = { ...content.props };
                const {
                    name, required, id, onKeyChange,
                    schema,
                    onDropPropertyClick
                } = props;
                const {
                    [ADDITIONAL_PROPERTY_FLAG]: isAdditionalProperty,
                    title,
                    ...newSchema
                } = schema;
                props.uiSchema = { ...props.uiSchema, 'ui:hideLabel': true };
                newSchema.title = " ";
                props.schema = newSchema;

                return (<tr key={idx}>
                    <td>{isAdditionalProperty ? (
                        <LabelInput
                            label={name}
                            required={required}
                            id={`${id}-key`}
                            onChange={onKeyChange}
                        />
                    ) : name}</td>
                    <td>
                        <SchemaField {...props} />
                    </td>
                    {isAdditionalProperty ? (<td>
                        <Button
                            color="primary2"
                            aria-label="Remove"
                            className="array-item-remove"
                            tabIndex="-1"
                            style={btnStyle}
                            disabled={readonly}
                            onClick={onDropPropertyClick(name)}
                        >X</Button>
                    </td>) : null}
                </tr>)
            })}
            {onAddClick ? (<tr><td colSpan="2">
                <Button
                    className="array-item-add"
                    color="primary"
                    onClick={onAddClick}
                    disabled={readonly}
                >
                    <i className="fa fa-plus" />
                    {uiSchema["ui:addButtonText"] ? ` ${uiSchema["ui:addButtonText"]}` : null}
                </Button>
            </td></tr>) : null}
        </tbody>
    </table>);
}

function LabelInput(props) {
    const { id, label, onChange } = props;
    return (
        <input
            className="form-control"
            type="text"
            id={id}
            onBlur={event => onChange(event.target.value)}
            defaultValue={label}
        />
    );
}

export function TitleField({
    isSection,
    sectionType='section',
    id: $id,
    title: propTitle,
    expandable,
    expanded,
    bindings: propBindings,
    toggleExpand
}) {
    const index = useMemo(() => (
        ($id || '').split('_').filter(x => /^[0-9]+$/.test(x)).pop() | 0
    ), [$id]);

    const bindings = useMemo(() => ({
        index, ...propBindings
    }), [index, propBindings]);

    const path = useMemo(() => getPathFromId($id || ''), [$id]);

    const title = useMemo(() => {
        if (!propTitle) return '';
        const { expr, scope, debug } = parseJnxExpr(propTitle, '.');

        return interpolate(expr, expr => (
            (new Jnx({ expr, scope, debug })).eval(bindings.rootFormData || {}, path, bindings)
        ));
    }, [propTitle, bindings, path]);

    return <legend
        id={`${$id}__title${isSection ? '' : '_ns'}`}
        className={`type-${sectionType}`}
        onClick={expandable ? toggleExpand : undefined}
    >
        {title}
        {expandable ? (<button className="expandable" onClick={toggleExpand}>
            <i className={expanded ? "fa fa-caret-up" : "fa fa-caret-down"} />
        </button>) : null}
    </legend>;
}

export default DefaultObjectFieldTemplate;