import { t, Trans } from "@lingui/macro";
import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import {
    Table,
    Button,
    Row,
    Col,
    Card,
    CardHeader,
    CardFooter,
    CardBody,
    CardTitle,
    CardText,
    Badge
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router';

import useAccessCheck, { checkAccess } from '../../util/useAccessCheck';
import useResourceLoader from '../../util/useResourceLoader';
import UWEEntityApi from '../../api/UWEEntityApi';
import Loader from '../Loader';
import Notification from '../Notification';
import useTitle from '../../util/useTitle';
import Pagination from '../Pagination';
import useSearchParams from '../../util/useSearchParams';
import SearchBar from "../Search/SearchBar";
import SortButton from "../SortButton";
import { AuthContext } from '../../context/AuthContext';
import { getObject, interpolate, mapObject } from '../../util/mapObject';
import WorkTrayIcon from './WorkTrayIcon';
import parseFormDefinition from '../../util/parseFormDefinition';
import ElementIcon from '../ElementIcon';
import { concatRoute, interpolateRoute, useRouteInterpolation } from '../../util/routeUtil';
import { IDENTITY_FORMATTER, useFormatter } from "../../util/applyFormatting";
import { useJnx } from "../../util/jnx";
import useOptionsLookup from "../customFields/hooks/useOptionsLookup";
import Diagram from "./Diagram";

function WorkTray(props) {
    const { element, scope } = props;

    const history = useHistory()

    useTitle(element.title);

    const auth = useContext(AuthContext);
    const {
        user: {
            user: { role: { extras } = {} } = {},
        } = {}
    } = auth;

    const defaultView = useMemo(() => extras ? JSON.parse(extras).defaultView : null, [extras]);

    const [formDefinition, sFilters] = parseSearchFormDefinition(element);
    const [filters, setFilters] = useState(sFilters);
    const [showFilter, setShowFilter] = useState(false);

    useEffect(() => setFilters({ ...(filters || {}), ...(defaultView || {}) }), [defaultView]);

    const toggleFilter = (e) => {
        e.preventDefault();
        setShowFilter(!showFilter);
    }

    const [
        [offset, setOffset],
        [size, setSize],
        [sortBy, setSortBy],
    ] = useSearchParams({
        offset: 0,
        size: 10,
        sortBy: element.defaultFilter
    });

    const {
        lookup,
        isSelected, setSelected,
    } = useWorktrayHooks(props);


    function mergeParamsIntoFilters(filters, params) {
        if (!params || typeof params !== "object") {
            return filters;
        }

        Object.entries(params).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                filters[key] = value.filter(Boolean).map(String).join(",");
            } else if (value != null && typeof value !== "object") {
                filters[key] = String(value);
            }
        });

        return filters;
    }

    const [paging, loading, error] = useResourceLoader(async () => {
        const resource = lookup.entity ? `uwe-entities/${lookup.entity}/list` : lookup.api.split(':')[1];
        if (!resource) return null;
        mergeParamsIntoFilters(filters, lookup?.params);
        const data = await UWEEntityApi.getWorkTray({ resource, offset, size, sortBy, ...filters });
        return Array.isArray(data) ? {
            from: 0,
            to: data.length,
            count: data.length,
            items: data
        } : data;
    }, [size, offset, sortBy, filters, element, lookup]);

    const {
        from = 0,
        to = 0,
        count = 0,
        items: data = [],
    } = paging || {};


    const onSearch = (update) => {
        setFilters({ ...filters, ...update });
    }

    const onClearSearch = () => setFilters({});

    const [tab, setTab] = useState("table");

    return (
        <div className={`worktray ${element[":className"]}`} onClick={e => { e.preventDefault() }} role="table">
            {element.canCreate ? <WorkTrayIcon element={element.canCreate} /> : null}
            <Card className={`inbox-card primary ${element.filters && showFilter ? '' : 'no-bottom-gap'}`}>
                <CardHeader>
                    <Row>
                        <Col {...element.tableActions ? { xs: 10, sm: 10 } : { xs: 10 }}>
                            <div className={`title ${element.tableActions ? 'has-buttons' : ''}`}>{element.title}</div>
                            {element.tableActions ? (
                                <ActionButtonsCell actions={element.tableActions} scope={scope} />
                            ) : null}
                            {element.canSeeDiagram ? (
                                <div className="float-right">
                                    {tab !== "tree" ? <Button color="secondary" onClick={() => setTab("tree")} ><i className="fa fa-network-wired" /> Ver Diagrama</Button> : <Button color="secondary" onClick={() => setTab("table")} ><i className="fa fa-table" /> Ver Tabla</Button>}
                                </div>
                            ) : null}
                        </Col>
                        {element.filters ? (<Col sm="2"><div className="float-right">
                            <Button onClick={toggleFilter} type="button" outline color="secondary" active={showFilter}>
                                <i className="glyphicon glyphicon-filter" />
                            </Button>
                        </div></Col>) : null}
                    </Row>
                </CardHeader>
                {showFilter ? <SearchBar
                    formDefinition={formDefinition}
                    filters={filters}
                    onSearch={onSearch}
                    onClearSearch={onClearSearch}
                />
                    : null}
            </Card>
            <Card className={`inbox-card ${showFilter ? '' : 'square-top'}`}>
                {element.canSeeDiagram && tab === "tree" ?
                    <Diagram data={data} dataDiagram={element.canSeeDiagramnt} history={history} />
                    :
                    <>
                        <Table className="hide-when-mobile">
                            <thead><tr>{element.columns.map(({ sortkey, title, style, headerStyle }, idx) => sortkey ? (
                                <SortButton key={idx}
                                    tag="th" sortKey={sortkey} sortBy={sortBy} setSortBy={setSortBy}
                                    style={style || headerStyle}
                                >{title}</SortButton>
                            ) : (
                                <th key={idx} style={style || headerStyle} >{title}</th>
                            ))}</tr></thead>
                            <tbody>{loading ? (
                                <tr><td colSpan="8">
                                    <Loader centered><Trans>Loading {element.title}</Trans></Loader>
                                </td></tr>
                            ) : (<>
                                {error ? (<tr><td colSpan="8">
                                    <Notification error={error} />
                                </td></tr>) : null}
                                {(data && data.length) ? data.map((rowObject, idx) => (
                                    <DataRow key={idx} columns={element.columns} rowObject={rowObject} scope={scope}
                                        selected={isSelected(rowObject)}
                                        setSelected={setSelected}
                                    />
                                )) : (<tr><td colSpan="7">
                                    <Notification color="warning"><Trans>The list of {element.title} is empty.</Trans></Notification>
                                </td></tr>)}
                            </>)}</tbody>
                        </Table>
                        <div className="mobile-table show-when-mobile">
                            {loading ? (
                                <Loader centered><Trans>Loading {element.title}</Trans></Loader>
                            ) : (<>
                                {(data && data.length) ? data.map((rowObject, idx) => (
                                    <MobileDataRow key={idx} columns={element.columns} rowObject={rowObject} scope={scope}
                                        selected={isSelected(rowObject)}
                                        setSelected={setSelected}
                                    />
                                )) : (
                                    <Notification color="warning"><Trans>The list of {element.title} is empty.</Trans></Notification>
                                )}
                            </>)}
                        </div>
                        <CardFooter className="pagination">
                            <div className="float-right">
                                <Pagination offset={from} count={count} size={size} setSize={setSize} setOffset={setOffset} />
                            </div>
                        </CardFooter>
                    </>
                }
            </Card>
        </div >
    );
}

function useWorktrayHooks({
    element,
    selected: propSelected,
    setSelected: propSetSelected
}) {
    const {
        selectable,
    } = element;
    const lookup = useMemo(() => element.lookup || ({
        entity: element.entityType,
        api: !element.entityType && element.resource ? `api:${element.resource}` : null,
        params: element.lookupParams
    }), [element?.lookup, element]);

    const idAttr = lookup.id;

    const { isSelected, setSelected } = useMemo(() => {
        let isSelected = () => false;
        let setSelected;

        if (selectable) {
            const valueFn = idAttr ? (x => x[idAttr]) : (x => x);
            isSelected = x => {
                const xValue = valueFn(x);
                return xValue === propSelected;
            };
            setSelected = x => propSetSelected(valueFn(x));
        }

        return { isSelected, setSelected };
    }, [selectable, idAttr, propSelected]);

    return {
        lookup,
        isSelected, setSelected
    };
}

WorkTray.rootSchema = {
    "definitions": {
        "mapFieldField": {
            "type": ["string", "object"],
            "ui:field": "JsonField",
            "showTree": true,
        },
        "requireRestrictfield": {
            "type": "array",
            ":classNames": "from-col-1-size-2",
            "ui:arrayType": "cards",
            "items": {
                "type": "object",
                "ui:addButtonText": "Add Option",
                "ui:positionButtons": "top-outside",
                "properties": {
                    "flag": {
                        "title": t`User Flag`,
                        "type": "string"
                    },
                    "role": {
                        "title": t`Role`,
                        "type": "string",
                        "ui:field": "LookupFormField",
                        "lookup": {
                            "api": 'roles',
                            "resource": t`Roles`,
                            "id": 'name',
                            "label": 'name'
                        }
                    },
                    "permission": {
                        "title": t`Permission`,
                        "type": "string",
                        "enum": ["canWorkAs", "canBeAssigned", "canAssign"],
                        "enumNames": [t`Can Work`, t`Can be Assigned`, t`Can Assign`]
                    }
                },
                "ui:field": "ToggleObjectField"
            }
        },
        "actionsField": {
            "type": "array",
            "title": "Actions",
            "ui:arrayType": "cards",
            "ui:newRow": true,
            "items": {
                "type": "object",
                "ui:addButtonText": "Add Action",
                "ui:positionButtons": "top",
                "ui:sectionType": "subsubsection",
                "properties": {
                    "obj": {
                        "type": "object",
                        ":classNames": "grid-2-columns",
                        "title": [
                            "# ${$index + 1}: ${label}",
                        ].join(''),
                        "ui:newSection": true,
                        "ui:expandable": true,
                        "properties": {
                            "label": {
                                "title": "Action",
                                "type": "string",
                                "map:field": "label"
                            },
                            "backgroundColor": {
                                "title": "Color",
                                "type": "string",
                                "map:field": "backgroundColor"
                            },
                            "route": {
                                "title": "Route",
                                "type": "string",
                                "map:field": "route"
                            },
                            "requireExpr": {
                                "title": "requireExpr",
                                "type": ["string", "object"],
                                "ui:field": "JsonField",
                                "map:field": "requireExpr"
                            },
                            "requirerestrict": {
                                "type": "object",
                                ":classNames": "from-col-1-size-2",
                                "title": "Conditions",
                                "ui:sectionType": "label",
                                "ui:field": "ToggleObjectField",
                                "properties": {
                                    "require": {
                                        "title": "Require Any",
                                        "map:field": "require",
                                        "$$include": "#/definitions/requireRestrictfield",
                                    },
                                    "restrict": {
                                        "title": "Restrict If Any",
                                        "map:field": "restrict",
                                        "$$include": "#/definitions/requireRestrictfield",
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    },
    "properties": {
        "title": {
            "title": "Title",
            "type": "string",
            "map:field": "title"
        },
        "className": {
            "title": "Class Names",
            "type": "string",
            "map:field": ":classNames"
        },
        "entityType": {
            "title": "Entity Type",
            "type": "string",
            "map:field": "entityType",
            "ui:field": "LookupFormField",
            "lookup": {
                "resource": "Entity Types",
                "api": "api:manage/objectschemas",
                "params": { "all": true },
                "options": { "useCache": true },
                "jnx": "data.items",
                "id": "name",
                "label": "name"
            }
        },
        "resource": {
            "title": "Resource",
            "type": "string",
            "map:field": "resource"
        },
        "lookupParams":{
            "title": "Params",
            "type": "object",
            "map:field": "lookupParams",
            "properties": {},
            "additionalProperties": {
                "type": "string",
                "title": "value"
            },
            "ui:asPropertiesTable": true
        },
        "tableActions": {
            "$$include": "#/definitions/actionsField",
            ":classNames": "from-col-1-size-3",
            "map:array": "tableActions"
        },
        "defaultFilter": {
            "title": "Default Filter",
            "type": "string",
            "map:field": "defaultFilter"
        },
        "filters": {
            "title": "Filters",
            ":classNames": "from-col-1-size-3",
            "type": "array",
            "map:array": "filters",
            "items": {
                "type": "object",
                "properties": {
                    "title": {
                        "type": "string",
                        "title": "title",
                        "map:field": "title"
                    },
                    "key": {
                        "type": "string",
                        "title": "key",
                        "map:field": "key"
                    },
                    "type": {
                        "type": "string",
                        "title": "type",
                        "map:field": "type"
                    },
                    "format": {
                        "type": "string",
                        "title": "format",
                        "map:field": "format"
                    },
                }
            }
        },
        "columns": {
            "title": "Columns",
            ":classNames": "from-col-1-size-3",
            "type": "array",
            "map:array": "columns",
            "items": {
                "type": "object",
                "ui:showColumnsIf": {
                    "actions": { "expr": "hasActions", "debug": "actions showColumnsIf", "scope": ".." }
                },
                "properties": {
                    "title": {
                        "type": "string",
                        "map:field": "title",
                        "title": "title"
                    },
                    "sortkey": {
                        "type": "string",
                        "map:field": "sortkey",
                        "title": "key"
                    },
                    "mapField": {
                        "$$include": "#/definitions/mapFieldField",
                        "map:field": "map:field",
                        "title": "Field"
                    },
                    "format": {
                        "type": "string",
                        "map:field": "format",
                        "title": "format"
                    },
                    "panel2": {
                        "ui:newRow": true,
                        "type": "object",
                        "title": "Extra Properties...",
                        "ui:field": "HideObjectField",
                        "ui:newSection": true,
                        "ui:sectionType": "subsubsection",
                        "ui:expandable": true,
                        "ui:expandedDefault": false,
                        ":classNames": "grid-2-columns bordered",
                        "properties": {
                            "color": {
                                "$$include": "#/definitions/mapFieldField",
                                "map:field": "color",
                                "title": "Color"
                            },
                            "valueTranform": {
                                "$$include": "#/definitions/mapFieldField",
                                "map:field": "valueTranform",
                                "title": "Value Tranform Expression"
                            },
                            "mapImage": {
                                "$$include": "#/definitions/mapFieldField",
                                ":classNames": "from-col-1-size-1",
                                "map:field": "map:image",
                                "title": "Image"
                            },
                            "iconSize": {
                                "title": "Icon Size",
                                "ui:showIf": { "scope": ".", "expr": "$isTruthy(mapImage)", debug: "iconSize showIf" },
                                "type": "string",
                                "enum": ["1em", "2em", "4em", "6em", "8em", "10em"],
                                "map:field": { "default": "icons.size", "path": "fontSize" }
                            },
                            "actions": {
                                "$$include": "#/definitions/actionsField",
                                ":classNames": "from-col-1-size-2",
                                "map:array": "actions"
                            }
                        }
                    },
                }
            }
        },
    }
}


function DataRow({ rowObject, columns, scope, selected, setSelected }) {
    return (<tr>{columns.map((cell, idx) => {
        return (<td key={idx} style={cell.style}>{cell.actions ? (
            <ActionButtonsCell actions={cell.actions} rowObject={rowObject} scope={scope} />
        ) : (
            <DataCell cell={cell} rowObject={rowObject}
                selected={selected}
                setSelected={setSelected}
            />
        )}</td>);
    })}</tr>);
}

function MobileDataRow({ rowObject, columns, scope, selected, setSelected }) {
    return (<Card>
        <CardBody>{columns.reduce((acc, cell, idx) => {
            if (cell.mobilePosition > 0 || 1) {
                acc.push(
                    (<>{cell.actions ? (
                        <ActionButtonsCell actions={cell.actions} rowObject={rowObject} scope={scope} />
                    ) : (
                        <CardText>
                            <MobileDataCell cell={cell} rowObject={rowObject}
                                selected={selected}
                                setSelected={setSelected}
                            />
                        </CardText>
                    )
                    }</>)
                )
            }
            return acc
        }, [])}</CardBody>
    </Card>);
}

function MobileDataCell({ rowObject, cell, selected, setSelected }) {
    const {
        'map:field': mapField,
        'map:image': mapImage,
        iconSize,
        format,
        type,
        checkbox,
        color: colorExpr,
        title
    } = cell;
    const emptyText = cell.emptyText || (checkbox ? '' : '---');

    const colorJnx = useJnx(colorExpr);

    const value = useMemo(() => mapField ? mapObject(rowObject, { "value": mapField }).value : null, [rowObject, mapField]);
    const color = useMemo(() => colorJnx?.eval(rowObject, '', { value }), [value, colorJnx, rowObject]);
    const formatter = useFormatter(format);
    const text = useMemo(() => (
        formatter !== IDENTITY_FORMATTER ? (
            formatter.apply(value)
        ) : (value !== null && value !== undefined ? (
            `${value}`
        ) : ''
        )), [value, formatter]);
    const image = useMemo(() => mapImage ? mapObject(rowObject, { "value": mapImage }).value : null, [rowObject, mapImage]);

    const onClick = useCallback((e) => {
        e.preventDefault();
        setSelected(rowObject);
    }, [rowObject, setSelected]);

    const flag = mapField ? value : selected;

    return <>
        {image ? <ElementIcon element={image} fontSize={iconSize} /> : null}
        {image ? ' ' : null}
        {checkbox ? (<button
            className={`icon-check-field btn ${flag ? 'btn-success' : 'btn-secondary'}`}
            color="secondary"
            onClick={onClick}
        >{flag ? <i className="fa fa-check" /> : null}</button>) : null}
        {type === 'badge' ? (
            <Badge color={color} style={{ fontSize: "100%" }} pill>
                {text || emptyText}
            </Badge>
        ) : (
            `${title}${title ? ":" : ""} ${text}` || emptyText || ""
        )}
    </>;
}


function DataCell({ rowObject, cell, selected, setSelected }) {
    const {
        'map:field': mapField,
        'map:image': mapImage,
        iconSize,
        format,
        type,
        checkbox,
        color: colorExpr,
        valueTranform: valueTranformExpr,
        isHTML = false
    } = cell;
    const emptyText = cell.emptyText || (checkbox ? '' : '---');

    const colorJnx = useJnx(colorExpr);
    const valueTranformJnx = useJnx(valueTranformExpr);

    const value = useMemo(() => {
        let value = mapField ? mapObject(rowObject, { "value": mapField }).value : null;

        if (valueTranformJnx) value = valueTranformJnx.eval(rowObject, '', { value })

        return value;
    }, [rowObject, mapField, valueTranformJnx]);

    const color = useMemo(() => colorJnx?.eval(rowObject, '', { value }), [value, colorJnx, rowObject]);
    const formatter = useFormatter(format);
    const text = useMemo(() => (
        formatter !== IDENTITY_FORMATTER ? (
            formatter.apply(value)
        ) : (value !== null && value !== undefined ? (
            `${value}`
        ) : ''
        )), [value, formatter]);
    const image = useMemo(() => mapImage ? mapObject(rowObject, { "value": mapImage }).value : null, [rowObject, mapImage]);

    const onClick = useCallback((e) => {
        e.preventDefault();
        setSelected(rowObject);
    }, [rowObject, setSelected]);

    const flag = mapField ? value : selected;

    return <>
        {image ? <ElementIcon element={image} fontSize={iconSize} /> : null}
        {image ? ' ' : null}
        {checkbox ? (<button
            className={`icon-check-field btn ${flag ? 'btn-success' : 'btn-secondary'}`}
            color="secondary"
            onClick={onClick}
        >{flag ? <i className="fa fa-check" /> : null}</button>) : null}
        {type === 'badge' ? (
            <Badge color={color} style={{ fontSize: "100%" }} pill>
                {text || emptyText}
            </Badge>
        ) : (
            isHTML ? HTMLCodeDisplay(text) :
            text || emptyText || ""
        )}
    </>;
}

const HTMLCodeDisplay = (htmlCode) => {
    return <div dangerouslySetInnerHTML={{ __html: `<div style="font-family: 'Inter'">${htmlCode}</div>` }} />;
  };
  

function ActionButtonsCell({ actions, rowObject, scope }) {
    const auth = useContext(AuthContext);

    const buttons = actions.map(obj => {
        const newObj = { ...obj };
        if (obj.requireExpr) {
            newObj.requireJnx = useJnx(obj.requireExpr);
        }
        return newObj;
    });

    const filteredActions = useMemo(() => buttons.filter(obj => {
        return (!obj.requireJnx || obj.requireJnx.eval(scope)) &&
            (!obj.require || obj.require.some((requirement) => checkButtonAccess(requirement, rowObject, auth))) &&
            (!obj.restrict || obj.restrict.every((requirement) => !checkButtonAccess(requirement, rowObject, auth)))
    }), [auth, buttons]);

    return <>{filteredActions.map((action, idx) => (
        <ActionButton key={idx} action={action} rowObject={rowObject} scope={scope} />
    ))}</>;
}

function checkButtonAccess({ role, permission, flag }, rowObject, auth) {
    return (role ? checkAccess(auth, role, permission) : true) && (flag ? getObject(rowObject, flag) : true)
}


function ActionButton({ action, rowObject, scope: propScope }) {
    const scope = useMemo(() => ({
        id: rowObject?.id,
        entityTypeSlug: rowObject?.entityTypeSlug,
        item: rowObject,
        ...propScope
    }), [rowObject, propScope]);

    const route = useRouteInterpolation(action.route, scope);

    return (<Link
        style={{ 'backgroundColor': action.backgroundColor?action.backgroundColor:null}}
        className="btn btn-primary"
        to={route}
    >{action.label}</Link>);
}

const parseSearchFormDefinition = ({ filters }) => {
    if (filters) {
        const sFilters = {};
        const schema = filters.reduce((_, { key, ...obj }) => {
            _[key] = obj;
            return _;
        }, {});
        return [parseFormDefinition({
            schemaProps: {
                ":classNames": "smallThreeRows",
            },
            schema
        }), sFilters];
    }
    return [];
};

export default WorkTray;