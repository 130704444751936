import React, { useEffect } from 'react'
import { TITLE_SUFFIX } from '../constants';


function useTitle(title){
    useEffect(() => {
        const suffix = TITLE_SUFFIX;
        document.title = title ? `${title} | ${suffix}` : suffix;
    }, [title])
}


export default useTitle;