import React, { useMemo } from "react";
import { FormGroup, Input } from "reactstrap";


function BoolYesNoField(props) {
    const {
        formData,
        disabled,
        idSchema: { $id },
        schema: { title },
        uiSchema: {
            'ui:readonly': uiReadonly,
            'akc:requiredIfVisible': requiredIfVisible,
            'ui:hideAttr': uiHideAttr,
            'ui:hideOnEmpty': hideOnEmpty
        },
        readonly: propReadonly,
        required,
        onChange: propOnChange
    } = props;

    const readonly = uiReadonly || propReadonly;
    const requiredField = !!required || !!requiredIfVisible

    const map = {true: 1, false: 0};

    function onChange({ target: { value } }) {
        propOnChange(value === "1");
    }


    if (uiHideAttr && readonly) {
        return null;
    }

    return (
        <FormGroup disabled={readonly || disabled}>
            {title.trim() ? (<label className="control-label" htmlFor={$id}>
                {title}{requiredField ? <span className="required">*</span> : null}
            </label>) : null}
            <Input
                id={$id}
                type="select"
                disabled={readonly || disabled}
                required={requiredField}
                value={formData in map ? map[formData] : 2}
                onChange={onChange}
            >
                <option value="2" disabled>---</option>
                <option value="1">Sí</option>
                <option value="0">No</option>
            </Input>
        </FormGroup>
    );
}


export default BoolYesNoField;