import { Trans } from "@lingui/macro";
import React, { useState } from "react";
import { Button, Card, CardBody, CardFooter, CardHeader } from "reactstrap";
import * as XLSX from 'xlsx/xlsx.mjs';
import FileInput from "../../../components/FileInput";
import BulkUploaderApi from "../../../api/BulkUploaderApi";
import useLoader from "../../../util/useLoader";
import Loader from "../../../components/Loader";
import Notification from "../../../components/Notification";

function BulkUploader() {

    const [importFile, setBulkUploaderFile] = useState();
    const [importing, errorBulkUploadering, importFn] = useLoader();
    const [importNotice, setBulkUploaderNotice] = useState();
    function onBulkUploader(model) {
        if (importFile) {
            importFn(async () => {
                setBulkUploaderNotice();
                await BulkUploaderApi.bulkInsert({ file: importFile, model });
                setBulkUploaderFile();
                setBulkUploaderNotice("Bulk Uploader sucessful.")
            });
        }
    }

    const [importFileUserRole, setBulkUploaderFileUserRole] = useState();
    const [importingUserRole, errorBulkUploaderingUserRole, importFnUserRole] = useLoader();
    const [importNoticeUserRole, setBulkUploaderNoticeUserRole] = useState();
    function onBulkUploaderUserRole(model) {
        if (importFileUserRole) {
            importFnUserRole(async () => {
                setBulkUploaderNoticeUserRole();
                await BulkUploaderApi.bulkInsert({ file: importFileUserRole, model });
                setBulkUploaderFileUserRole();
                setBulkUploaderNoticeUserRole("Bulk Uploader sucessful.")
            });
        }
    }

    const generateExcelFile = async (model) => {
        await BulkUploaderApi.getModel({ model }).then(worksheetData => {
            if (worksheetData) {
                const workbook = XLSX.utils.book_new();
                const worksheet = XLSX.utils.aoa_to_sheet([worksheetData]);
                XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
                const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const downloadLink = document.createElement('a');
                downloadLink.href = URL.createObjectURL(blob);
                downloadLink.download = `${model}.xlsx`;
                downloadLink.click();
            }
        });

    };

    return <div className="row">
        <div className="col-lg-6">
            <Card className="inbox-card primary">
                <CardHeader><Trans>Bulk User Uploader</Trans></CardHeader>
                <CardBody>
                    <div className="pb-3">
                        Seleccionar archivo: <FileInput file={importFile} onFileSelected={setBulkUploaderFile} />
                    </div>
                    {importNotice ? <Notification color="success">{importNotice}</Notification> : null}
                    {errorBulkUploadering ? <Notification error={errorBulkUploadering} /> : null}
                </CardBody>
                <CardFooter className="row">
                    <div className="col-lg-6">
                        <Button className="btn-sm" onClick={() => generateExcelFile('user')} color="primary"><Trans>Descargar Modelo</Trans></Button>
                    </div>
                    <div className="col-lg-6 text-right">
                        {importing ? <Loader /> : (
                            <Button className="btn-sm float-right" onClick={() => onBulkUploader('users')} color="primary"><Trans>Subir Usuarios</Trans></Button>
                        )}
                    </div>
                </CardFooter>
            </Card>
        </div>
        <div className="col-lg-6">
            <Card className="inbox-card primary">
                <CardHeader><Trans>Bulk User-Role Uploader</Trans></CardHeader>
                <CardBody>
                    <div className="pb-3">
                        Seleccionar archivo: <FileInput file={importFileUserRole} onFileSelected={setBulkUploaderFileUserRole} />
                    </div>
                    {importNoticeUserRole ? <Notification color="success">{importNoticeUserRole}</Notification> : null}
                    {errorBulkUploaderingUserRole ? <Notification error={errorBulkUploaderingUserRole} /> : null}
                </CardBody>
                <CardFooter className="row">
                    <div className="col-lg-6">
                        <Button className="btn-sm" onClick={() => generateExcelFile('user-role')} color="primary"><Trans>Descargar User-Role Model</Trans></Button>
                    </div>
                    <div className="col-lg-6 text-right">
                        {importingUserRole ? <Loader /> : (
                            <Button className="btn-sm float-right" onClick={() => onBulkUploaderUserRole('user-role')} color="primary"><Trans>Subit User-Role</Trans></Button>
                        )}
                    </div>
                </CardFooter>
            </Card>
        </div>
    </div>;
}


export default BulkUploader;