import { t } from '@lingui/macro';
import React, { useEffect } from 'react';
import { Redirect, useParams } from 'react-router';
import SequenceApi from "../../../api/SequenceApi";
import FormComponent from '../../../components/FormComponent';
import { FORM_UPDATE_SUBMIT_BUTTON } from '../../../constants';
import parseFormDefinition from '../../../util/parseFormDefinition';


export const EditSequence = FormComponent({
    ...parseFormDefinition({
        page: 'EditSequence',
        title: t`Sequence`,
        schema: {
            obj: {
                title: ["${$isTruthy(name) ? name : '", t`Unnamed Sequence`, "'}"].join(''),
                "ui:newSection": true,
                type: "object",
                properties: {
                    name: {
                        "map:field": "name",
                        title: t`Name`,
                        type: "string"
                    },
                    format: {
                        "map:field": "format",
                        title: t`Format`,
                        type: "string"
                    },
                    currentIndex: {
                        "map:field": "currentIndex",
                        title: t`Current Index`,
                        type: "number"
                    }
                }
            }
        },
    }),
    withoutNavigation: true,
    parseProps: ({ sequenceId, setSequenceId }) => ({
        id: sequenceId,
        setId: setSequenceId
    }),
    loadData: async ({ id }) => id === 'new' ? {} : SequenceApi.get({ id }),
    async onSubmit({ object }, { props: { id } }) {
        return await (id === 'new') ? (
            SequenceApi.create(object)
        ) : (
            SequenceApi.update(id, object)
        )
    },
    submitButtons: FORM_UPDATE_SUBMIT_BUTTON,
    renderFormSubmitted: () => (
        <Redirect to="../sequences" />
    )
});

export default EditSequence;