import { t } from "@lingui/macro";
import React, { useContext, useEffect, useMemo, useRef } from "react";
import { useHistory, useParams } from "react-router";
import { Nav, NavItem, NavLink, TabContent, TabPane, Button } from "reactstrap";

import SLASetting from "./SLASetting";
import Forms from "./Forms";
import ObjectSchemas from "./ObjectSchema";
import Sequences from "./Sequences";
import UserTable from "../users/UsersTable";
import OrganizationalUnitsTable from "./OrganizationalUnit/OrganizationalUnitsTable";
import WorkflowIndex from "./Workflow";
import Export from "./Export";
import IntegrationsTable from "./Integrations/IntegrationsTable";
import InternalLayout from "../../components/layouts/InternalLayout";
import { AuthContext } from "../../context/AuthContext";
import useLoader from "../../util/useLoader";
import IntegrationsApi from "../../api/IntegrationsApi";
import Loader from "../../components/Loader";
import Jnx from "../../util/jnx";
import { LABEL } from "../../LanguageConstant";
import ListInvoiceItem from "./Invoce/ListInvoiceItem";
import Dashboards from "./Dashboards";
import Roles from "./Roles";
import Faqs from "./Faq/index";
import BulkUploader from "./BulkUploader";

const TABS = {
    undefined: { noTab: true, component: ({setActiveTab}) => <SwitchBoard title={LABEL.selectComponent} buttons={TABS} setActive={setActiveTab}/> },
    'users-jedi': {
        icon: "fa fa-user",
        title: 'Usuarios', permission: 'ManageUsersInUnit', jnx: 'user.user.jediId',
        component: () => <Loader />,
        onActivate({ loadFn }) {
            loadFn(async () => {
                const url = await IntegrationsApi.GetOperationUrl('jedi/jump-to-jedi/login');
                if (url) {
                    window.open(url, '_blank');
                }
            });
        }
    },
    'users': {
        icon: "fa fa-user",
        title: LABEL.users, component: UserTable, permission: 'ManageUsersInUnit', jnx: '$not(user.user.jediId)'
    },
    'roles': {
        icon: "fa fa-user-astronaut",
        title: t`Roles`, component: Roles, permission: 'ManageRole'
    },
    'organizationalunits': {
        icon: "fa fa-building",
        title: LABEL.agencies, component: OrganizationalUnitsTable, permission: 'ManageOrganizationalUnits'
    },
    'objectSchema': {
        icon: "fa fa-list",
        title: LABEL.objectTypes, component: ObjectSchemas, permission: 'ManageWorkflow'
    },
    'dashboard': {
        icon: "fa fa-tachometer-alt",
        title: t`Dashboards`, component: Dashboards, permission: 'ManageWorkflow'
    },
    'workflow': {
        icon: "fa fa-network-wired",
        title: LABEL.workflow, component: WorkflowIndex, permission: 'ManageWorkflow'
    },
    'forms': {
        icon: "fa fa-clipboard-list",
        title: LABEL.forms, component: Forms, permission: 'ManageWorkflow'
    },
    'sla': {
        icon: "fa fa-traffic-light",
        title: LABEL.sla, component: SLASetting, permission: 'ManageWorkflow'
    },
    'integrations': {
        icon: "fa fa-link",
        title: LABEL.integrations, component: IntegrationsTable, permission: 'ManageIntegrations'
    },
    'invoice': {
        icon: "fa fa-money-bill",
        title: LABEL.invoiceItems, component: ListInvoiceItem, permission: 'ManageWorkflow'
    },
    'sequences': {
        icon: "fa fa-tag",
        title: t`Sequences`, component: Sequences, permission: 'ManageWorkflow'
    },
    'faq': {
        icon: "fa fa-question-circle",
        title: t`Faq`, component: Faqs, permission: 'ManageFaq'
    },
    'export': {
        icon: "fa fa-download",
        title: t`Export`, component: Export, permission: 'ManageWorkflow'
    },
    'bulkUploader': {
        icon: "fa fa-upload",
        title: t`Bulk Uploader`, component: BulkUploader, permission: 'ManageWorkflow'
    },
}

const DEFAULT_TAB = Object.keys(TABS)[0];

function SystemSettings() {
    const history = useHistory();
    const { setting = DEFAULT_TAB } = useParams();
    const auth = useContext(AuthContext);
    const { permissions } = auth?.user || {};
    const [loading, error, loadFn] = useLoader();

    const tabs = useMemo(() => Object.entries(TABS).reduce((_, [tab, tabDef]) => {
        if (
            (tabDef.permission ? (permissions && permissions[tabDef.permission]) : true) &&
            (tabDef.jnx ? (new Jnx(tabDef.jnx)).eval(auth || {}, '') : true)
        ) {
            tabDef.name = tab;
            _[tab] = tabDef;
        }
        return _;
    }, {}), [permissions, auth]);

    const activeTab = tabs[setting];
    const { component: Component } = activeTab || {};

    function setActiveTab(tab) {
        history.push(`/system-settings/${tab}`);
    }

    const context = useRef();
    context.current = {
        prefix: `/system-settings/`,
        auth,
        history,
        loadFn
    };

    useEffect(() => {
        if (activeTab?.onActivate) {
            activeTab.onActivate(context.current);
        }
    }, [activeTab]);

    return <InternalLayout
        tabs={tabs}
        activeTab={setting}
        setActiveTab={setActiveTab}
    ><div>
        {loading ? <Loader /> : (
            <TabContent activeTab={setting}>
                {Component ? (
                    <TabPane tabId={setting}><Component activeTab={setting} setActiveTab={setActiveTab} />
                    </TabPane>) : null}
            </TabContent>
        )}
    </div></InternalLayout>;
}


function SwitchBoard({
    title,
    buttons: propButtons,
    setActive
}){
    const auth = useContext(AuthContext);
    const { permissions } = auth?.user || {};
    const rows = useMemo(() => Object.entries(propButtons).reduce((_, [tag, def]) => {
        if (
            !def.noTab &&
            (def.permission ? (permissions && permissions[def.permission]) : true) &&
            (def.jnx ? (new Jnx(def.jnx)).eval(auth || {}, '') : true)
        ) {
            if (!_.length || _[_.length - 1].length >= 2) _.push([]);
            _[_.length - 1].push({tag, def});
        }
        return _;
    }, []), [permissions, auth, propButtons]);

    return <>
        <div>{title}</div>
        {rows.map((buttons, idx) => (
            <div key={idx} className="row">{buttons.map(({tag, def:{icon, title}}) => (
                <div key={tag} className="col-lg-4">
                    <Button onClick={() => setActive(tag)} color="primary" block>
                        <i className={icon} /><br />
                        {title}
                    </Button>
                </div>
            ))}</div>
        ))}
    </>;
}


export default SystemSettings;