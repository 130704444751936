import React, { useMemo } from 'react';
import useResourceLoader from '../util/useResourceLoader';
import UWEEntityApi from '../api/UWEEntityApi';
import Loader from '../components/Loader';

const CURRENT = "current";
const COMPLETE = "complete";
const PROGRESS_CLASSES = {
    current: 'current_state',
    complete: 'complete_state'
};

function UWEEntityProgress({ entity }) {

    const [ENTITY_STEPS, loadingSteps] = useResourceLoader(
        () => UWEEntityApi.getUWEEntityPreviousLog({ entityId: entity.id }),
        [entity],
        []
    );

    const mergeSTEPS = ENTITY_STEPS.includes(entity?.status?.[0]?.step) ? ENTITY_STEPS : [...ENTITY_STEPS, entity?.status?.[0]?.step];

    return loadingSteps ? <Loader /> : (
        mergeSTEPS.map((step, idx) => <ProgressStep
            key={idx} step={step} entity={entity}
        />)
    )
}


function ProgressStep({ entity, step }) {

    const className = "";

    const classProgress = useMemo(() => {

        const { status = [], state = {} } = entity || {};

        if (status?.[0]?.step === step) {
            return CURRENT;
        } else {
            return COMPLETE;
        }
    }, [step, entity]);

    return <div className={`rowProgress ${className || ''} ${PROGRESS_CLASSES[classProgress] || ""}`}>{step}</div>
}

export default UWEEntityProgress;
