import { t, Trans } from '@lingui/macro';
import React, { useMemo } from 'react';
import ObjectEditorField from '../_formEditor/ObjectEditorField';
import StringField from '../_formEditor/ObjectEditorField/StringField';

const FIELD_MAP = [
    {
        property: "title", label: t`Title`, type: "string",
    },
    {
        property: "initial", label: t`Is Initial`, type: "boolean",
        showIf: { expr: "type != 'end'" }
    },
    {
        property: "type", label: t`Type`, type: "select", options: [
            { value: "form"              , label: t`Form` },
            { value: "externalProcess"   , label: t`External Process` },
            { value: "calculation"       , label: t`Calculation` },
            { value: "branch"            , label: t`Decision` },
            { value: "documentGeneration", label: t`Document Generation` },
            { value: "notification"      , label: t`Notification` },
            { value: "emailForm"         , label: t`Email Form` },
            { value: "end"               , label: t`End` },
        ]
    },
    {
        property: "email", label: t`Email`, type: "object",
        properties: [
            {
                property: "recipient", label: t`Recipient`, type: "string",
            },
            // {
            //     property: "sender", label: t`Sender`, type: "string",
            // },
            {
                property: "emailFormName", label: t`Form Name`, type: "string", format: "string",
            },
            {
                property: "subject", label: t`Subject`, type: "string",
            },
            {
                property: "message", label: t`Message`, type: "string", format: "textarea",
            }
        ],
        showIf: { expr: "type = 'emailForm'" }
    },
    {
        property: "page", label: t`Page`, type: "select", optionsFromScope: "pages", options: true, showIf: { expr: "type = 'form'" }
    },
    {
        property: "role", label: t`Role`, type: "select", options: []
    },
    {
        property: "selector", label: t`Branch Selector`, type: "json", showIf: { expr: "type = 'branch'" }
    },
    {
        property: "onArrive", label: t`On Arrive Action`, type: "json",
    },
    {
        property: "transitions", label: t`Transitions`, type: "array", items:
        {
            type: "object",
            properties: [
                {
                    property: "name", label: t`Name`, type: "string",
                },
                {
                    property: "next", label: t`Next`, type: "select", optionsFromScope: "steps", options: true
                },
                {
                    property: "action", label: t`Action`, type: "json",
                },
                {
                    property: "buttonColor", label: t`Button Color`, type: "select", options: [
                        { value: "#77777a", label: t`System` },
                        { value: "#d26a5f", label: t`Red` },
                        { value: "#0b136d", label: t`Blue` },
                        { value: "#28a745", label: t`Green` },
                        { value: "#eac15d", label: t`Yellow` },
                    ],
                    showIf: { expr: "type = 'emailForm'" }
                }
            ]
        },
    },
    {
        property: "notification", label: t`Notification`, type: "object",
        properties: [
            {
                property: "service", label: t`Service`, type: "select", options: [
                    { value: "email", label: t`Email` },
                    { value: "desktop", label: t`Desktop Notification` },
                ]
            },
            {
                property: "scheduled", label: "Notification Scheduled", type: "boolean"
            },
            {
                property: "interval", label: "Run In", type: "string", showIf: { expr: "notification.scheduled" }
            },
            {
                property: "timeUnit", label: "Time Unit", type: "select", options: [
                    { value: "Day", label: "Days" },
                    { value: "Hour", label: "Hours" },
                    { value: "Minute", label: "Minutes" },
                ], showIf: { expr: "notification.scheduled" }
            },
            {
                property: "from", label: "Calculate From", type: "string", showIf: { expr: "notification.scheduled" }
            },
            {
                property: "senderRole", label: t`Sender Role`, type: "string",
            },
            {
                property: "subject", label: t`Subject`, type: "string",
            },
            {
                property: "message", label: t`Message`, type: "string", format: "textarea",
            }
        ],
        showIf: { expr: "type = 'notification'" }
    },
    {
        property: "documentGeneration", label: t`Document`, type: "object",
        properties: [
            {
                property: "documentName", label: t`DocumentName`, type: "string",
            }
        ],
        showIf: { expr: "type = 'documentGeneration'" }
    }
];

function ObjectWorkflowEditor({
    setPath,
    object,
    state,
    onChange: propOnChange,
    forms,
    onDelete,
    onDuplicate
}) {

    const { roles, steps } = state;
    const scope = useMemo(() => ({
        steps: Object.entries(steps).map((obj, idx) => ({
            value: obj[0],
            label: obj[1].title
        })),
        pages: forms && Object.keys(forms).length ? Object.entries(forms).map((obj, idx) => ({
            value: obj[1].page,
            label: obj[1].name
        })) : {}
    }), [steps, forms]);

    FIELD_MAP.map((entry) => {
        if (entry.property === "role") {
            entry.options = roles.map((role) => ({ value: role, label: role }));
        }
        return entry
    });

    function onChange(prop, value) {
        const newObject = { ...object };
        newObject[prop.property] = value;
        propOnChange(newObject);
    }

    return (
        <div className="container" > <div className='row _formEditor'>
            <div className="col-lg-12">
                <StringField label="Name" property="name" value={object.name} onChange={value => setPath(value)} />
            </div>
            {FIELD_MAP.map((prop, idx) => {
                return <ObjectEditorField key={idx} id={idx}
                    scope={scope}
                    fieldProps={prop}
                    object={object}
                    value={object ? object[prop.property] : null}
                    onChange={value => onChange(prop, value)}
                />
            })}
            <div className="col-lg-12 d-flex justify-content-between">
                <button className='btn btn-sm btn-success' onClick={() => onDuplicate(object.name)}><Trans>Duplicate</Trans></button>
                <button className='btn btn-sm btn-danger' onClick={() => onDelete(object.name)}><Trans>Delete</Trans></button>
            </div>
        </div>
        </div>
    );
}

export default ObjectWorkflowEditor;