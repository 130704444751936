import { t } from "@lingui/macro";
import FormComponent from '../../../components/FormComponent';
import parseFormDefinition from '../../../util/parseFormDefinition';


export const RoleDetailsEditor = FormComponent({
    ...parseFormDefinition({
        displayName: 'RoleDetailsEditor',
        title: t`Role Details`,
        schemaProps: {
            "title": [t`Role`, " ${name}"].join(''),
            "ui:newSection": true,
            ":classNames": "grid-2-columns",
        },
        schema: {
            // type: "object",
            "name": {
                "title": t`Name`,
                "map:field": "name",
                "type": "string",
            },
            "flags": {
                ":classNames": "from-col-1-size-1 flex-h",
                "type": "object",
                "title": " ",
                "properties": {
                    "canWorkUnassigned": {
                        "title": t`Can Work Unassigned`,
                        "map:field": "extras.canWorkUnassigned",
                        "type": "boolean",
                    },
                }
            },
            entities: {
                title: "Entities",
                ":classNames": "from-col-1-size-3",
                type: "object",
                'map:field': 'entities',
                properties: {},
                additionalProperties: {
                    keyFromLookup: {
                        lookup: {
                            api: 'object-schemas',
                            resource: t`object-schemas`,
                            id: 'id',
                            label: 'name'
                        },
                    },
                    type: "object",
                    title: " ",
                    properties: {
                        "homeTray": {
                            "type": "boolean",
                            "title": "Tray in Home",
                        }
                    }
                }
            },
            dashboards: {
                title: "Dashboards",
                ":classNames": "from-col-1-size-3",
                type: "object",
                'map:field': 'dashboards',
                properties: {},
                additionalProperties: {
                    keyFromLookup: {
                        lookup: {
                            api: 'dashboards',
                            resource: t`dashboards`,
                            id: 'id',
                            label: 'name'
                        },
                    },
                    type: "boolean",
                    title: " "
                }
            }
        },
    }),
    withoutNavigation: true,
    parseProps: ({ role, onChange }) => ({
        role,
        onRoleChange: onChange
    }),
    loadData: ({ role }) => role,
    hideDataLoader: true,
    onChange({
        formObject,
        parsedProps: {
            onRoleChange
        }
    }) {
        if (!formObject) return;
        onRoleChange(formObject);
    },
    onSubmit: ({ formData }) => Promise.resolve(),
    submitButtons: {
        impersonate: {
            text: "Impersonar", className: "btn-secondary", "onBeforeSubmit": async (submitEvent) => {
                submitEvent.preventDefault();
                console.log("siempre", submitEvent?.formData)
            },
        }
    }
});

export default RoleDetailsEditor;
